import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import loginImage from '../../../assets/img/login/login.png';
const AdminLogin = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const buttonStyle = {
    backgroundColor: 'lightblue',
    color: 'black',
    padding: '10px 20px',
    borderRadius: '5px',
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Implement your login logic here, e.g., API call or authentication.
  };

  return (
    <div className='w-100'>
      <div className="container-fluid mh-100 ">
        <div className="row no-gutters w-100 justify-content-center">
          {/* <div className="col-md-6 d-none d-md-block">
            <img src={loginImage} alt="Login Image object-cover full-screen-image"
              className="img-fluid"
              style={{ minHeight: '100%' }} />
          </div> */}
          <div className="col-md-6 bg-white d-flex flex-column align-items-center pt-40">
            <p className="tradebook-sty mb-80">TRADEBOOK</p>
            <div className="form-style d-flex flex-column align-items-center justify-content-center col-md-9">
              <p className='text-center font-20 font-weight-bold'>Log in</p>
              <p className='text-center'>Enter your credentials to Login as Admin</p>
              <form className='w-100'>
                <div className="form-group ">
                <label for="exampleInputEmail1">Username</label>
                  <input type="email" placeholder="Username" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="form-group pb-3">
                  <label for="Password">Password</label>
                  <input type="password" placeholder="Password" className="form-control" id="Password" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <input id='check' name="check" type="checkbox" value="" /> 
                    <label className="form-check-label ml-2" for="check">Remember Me?</label>
                  </div>
                  <div className='mr-2'>
                    <Link className='tradebook-color' to='/forgotpassword'>Forgot Password?</Link>
                    {/* <a href="#">Forgot Password?</a> */}
                   
                  </div>
                </div>
                <div className="pb-2">
                  <button type="submit" className="btn btn-dark mt-2 w-100" style={buttonStyle}>Log into Account</button>
                </div>
              </form>
              
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default AdminLogin;
