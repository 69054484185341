import BrokerDashboard from "./Clients/Admin/Dashboard/dashboards";
import EmployerContacts from "./Clients/Admin/EmployerContacs";
import FundedPins from "./Clients/Admin/FundedPins";
import UnfundedPins from "./Clients/Admin/UnfundedPins";
import UploadSchedule from "./Clients/Admin/UploadSchedule";
import AdminLogin from "./Clients/Admin/login";
import Login from "./Clients/Login/login";



export const routeConfig = [

    { path: '/login', component: Login, needsNav: false },
    { path: '/admin-login', component: AdminLogin, needsNav: true },
    { path: '/dashboard', component: BrokerDashboard, needsNav: true },
    { path: '/funded-pins', component: FundedPins, needsNav: true },
    { path: '/unfunded-pins', component: UnfundedPins, needsNav: true },
    { path: '/upload-schedule', component: UploadSchedule, needsNav: true },
    { path: '/employer-contacts', component: EmployerContacts, needsNav: true },
  ];