import { useEffect, useState } from 'react';

import { get } from "../../../api-services/fetch";
import Loading from './Loading';
import ResponsivePagination from 'react-responsive-pagination';
import Pulse from './pulse';

export default function FundedPins() {

    const [fundedPins, setFundedPins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const getStaffList = async (page = 1) => {
        setIsLoading(true)
        let res = await get({ endpoint: `api/fundedpins?ecode=PRBNK0000070&page=${page}&pageSize=${100}` })
        console.log(res)
        if (res?.status === 200) {
            setFundedPins(res?.data?.data)
            setIsLoading(false)
            setTotalPages(parseInt(res?.data?.totalCount/10))
           
        }
    }

    const handlePageChange = (page) =>{
        setCurrentPage(page);
        getStaffList(page);
    }


    useEffect(() => {
        getStaffList();
    }, [])


    return (
        <div>
            <div className='container'>
                <div >
                    <div className="row mt-40 table-responsive w-100">
                        <div className='row space-between mt-80'>
                            <h3 className='w-50'>
                                Funded Pins
                            </h3>
                            {/* <div className="w-50">
                                <div class="form-group has-search">
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search here..." />
                                </div>
                            </div> */}
                        </div>

                        {!isLoading ? <table className="table w-100">
                            <thead style={{ background: "#A6CE39" }}>
                                <tr className="green-bg">
                                    <th>PIN</th>
                                    <th>Firstname</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>PIN Upload Date</th>
                                    <th>Last Funding Date</th>
                                    <th>Last Funding Period</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array?.isArray(fundedPins) &&
                                    fundedPins?.map((staff) => (
                                        <tr key={staff?.pin}>
                                            <td>{staff?.pin}</td>
                                            <td>{staff?.firstname}</td>
                                            <td>{staff?.surname}</td>
                                            <td>{staff?.othernames}</td>
                                            <td>{new Date(staff?.uploaD_DATE).toLocaleDateString()}</td>
                                            <td>{new Date(staff?.uploaD_DATE).toLocaleDateString()}</td>
                                            <td>{new Date(staff?.uploaD_DATE).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table> : <Pulse />}
                    </div>
                    <ResponsivePagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                </div>
            </div>

        </div>
    )
}