import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import loginImage from '../../../assets/img/login/login.png';
import A from '../../../assets/A.jpg';
import B from '../../../assets/B.jpg';
import C from '../../../assets/C.jpg';
import D from '../../../assets/D.jpg';
import E from '../../../assets/E.jpg';
import F from '../../../assets/F.jpg';
import logo from "../../../assets/fidpen_logo.png"
import { post } from "../../../api-services/fetch"
import { useNavigate } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import notification from '../Admin/notification';
import Pulse from '../Admin/pulse';
import Loading from '../Admin/Loading';


const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  // const buttonStyle = {
  //   backgroundColor: 'lightblue',
  //   color: 'black',
  //   padding: '10px 20px',
  //   borderRadius: '5px',
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    let res = await post({ endpoint: "api/employee/login", body: formData })
    console.log(res)
    if (res?.data?.code === 1) {
    setIsLoading(false)
      sessionStorage?.setItem("token", res?.data?.token);
      sessionStorage?.setItem("name", res?.data?.data?.agenT_NAME);
      sessionStorage?.setItem("code", res?.data?.data?.agenT_CODE);
      sessionStorage?.setItem("email", res?.data?.data?.email);
      navigate("/dashboard");
    }
    else{
      notification({
        title: "Login Failed",
        type: "danger",
        container: "top-right",
        message: res?.data?.message
      })
    setIsLoading(false)

    }
    console.log(res);
    // Implement your login logic here, e.g., API call or authentication.
  };

  return (
    <div className='w-100'>
      <div className="container-fluid mh-100 ">
        <div className="row no-gutters w-100">
          <div className="col-md-6 d-none d-md-block">

            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              // navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={A} alt="Login Image"
                  className="img-fluid object-contain full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={B} alt="Login Image"
                  className="img-fluid object-cover full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={C} alt="Login Image"
                  className="img-fluid object-cover full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={D} alt="Login Image"
                  className="img-fluid object-cover full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={E} alt="Login Image"
                  className="img-fluid object-cover full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={F} alt="Login Image"
                  className="img-fluid object-cover full-screen-image"
                  style={{ minHeight: '100%' }} />
              </SwiperSlide>
            </Swiper>


          </div>
          <div className="col-md-6 bg-white d-flex flex-column align-items-center pt-40">
            {/* <p className="tradebook-sty mb-80">TRADEBOOK</p> */}
            <img className="mb-40 w-60" src={logo} alt="" />
            <div className="form-style d-flex flex-column align-items-center justify-content-center col-md-9">
              <p className='text-center font-20 font-weight-bold pb-20'>Log in</p>
              <p className='text-center'>Enter your credentials to access your account</p>
              <form className='w-100'>
                <div className="form-group ">
                  <label for="exampleInputEmail1">Employer's Code</label>
                  <input onChange={handleInputChange} name="email" type="text" placeholder="Employer's Code" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="form-group pb-3">
                  <label for="Password">Password</label>
                  <input onChange={handleInputChange} name="password" type="password" placeholder="Password" className="form-control" id="Password" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <input id='check' name="check" type="checkbox" value="" />
                    <label className="form-check-label ml-2" for="check">Remember Me?</label>
                  </div>
                  {/* <div className='mr-2'>
                    <Link className='tradebook-color' to=''>Forgot Password?</Link>
                   
                  </div> */}
                </div>
                <div className="pb-2">
                  <button disabled = {isLoading} onClick={handleLogin} className='btn w-100 mt-2' >{isLoading ? <Loading className = "w-100"/> :" Log into Account"}</button>
                </div>
              </form>
              {/* <div className="sideline">OR</div> */}

              {/* <div className="pt-4 text-center">
                Are you new here?
                <Link to='' className='tradebook-color'> Create Account</Link>

              </div> */}
            </div>

          </div>
        </div>
      </div>
    </div>

  );
};

export default Login;
