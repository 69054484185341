
import { useEffect, useState } from "react";
import { get, numberWithCommas, post } from "../../../api-services/fetch";
import notification from "./notification"
import Loading from "./Loading";
import Pulse from "./pulse";


export default function UploadSchedule() {

    const [formData, setFormData] = useState({
        AmountPaid: "",
        OtherComments: '',
        PaymentDate: '',
        FileUpload: '',
    });

    const [schedules, setSchedules] = useState({});

    const [isLoading, setIsLoading] = useState(false)

    const getUploadedSchedules = async () => {

        let res = await get({ endpoint: `api/submitted-schedules?ecode=${"PRBNK0000070"}` })
        if (res?.data?.code === 1) {
            setSchedules(res?.data?.data);
        }
        console.log(res)
    }

    useEffect(() => {
        getUploadedSchedules();
    }, [])



    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(formData)

        let res = await post({ endpoint: "api/submit-schedule", body: { ...formData, employerId: "PRBNK0000070" }, multipart: true })

        if (res?.status === 200) {
            setIsLoading(false)
            notification({
                title: "File Upload Success",
                message: "Schedule uploaded successfully",
                type: "success",
            });
            getUploadedSchedules();
            setFormData(
                {
                    AmountPaid: "",
                    OtherComments: '',
                    PaymentDate: '',
                    FileUpload: '',
                }
            )
        }
        else {
            setIsLoading(false)
            notification({
                title: "Error",
                message: res?.data?.message,
                type: "danger",
            });
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "AmountPaid") {
            setFormData({
                ...formData,
                [name]: parseFloat(value),
            });
        }
        else if (name === "PaymentDate") {
            setFormData({
                ...formData,
                [name]: new Date(value).toISOString(),
            });
        }
        else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }

    };

    const handleFileChange = (e) => {
        const { name, value } = e.target;
        if (e.target.files) {

            setFormData({
                ...formData,
                [name]: e.target.files[0],
            });
        }
    };



    return (
        <div>
            <div className='d-flex card white-bg flex-column align-items-center'>
                <div className='w-75 d-flex justify-content-center'>
                    <div className="container mt-40">
                        <h2 className='font-weight-bold w-100 '>Upload Schedule</h2>

                        <form className='customLabels' onSubmit={handleSubmit} >
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="AmountPaid" >Amount Paid</label>
                                    <input type="number" className="form-control" id="AmountPaid" name='AmountPaid' placeholder="Enter Amount" value={formData.AmountPaid}
                                        onChange={handleChange} />
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className="form-group col-md-6">
                                    <label htmlFor="PaymentDate">Date of Payment</label>
                                    <input type="date" className="form-control" id="PaymentDate" name='PaymentDate' placeholder="Payment date"
                                        onChange={handleChange} />
                                </div>
                            </div>


                            <div className='form-row'>
                                <div className="form-group col-md-6">
                                    <label htmlFor="OtherComments">Comments</label>
                                    <textarea type="text" className="form-control" id="OtherComments" name='OtherComments' placeholder="Additional Comments" value={formData.OtherComments}
                                        onChange={handleChange} />
                                </div>
                            </div>

                            <div className='form-row'>
                                <div className="form-group col-md-6">
                                    <label htmlFor="FileUpload">Upload</label>
                                    <input type="file" className="form-control" id="FileUpload" name='FileUpload'
                                        onChange={handleFileChange} />
                                </div>
                            </div>

                            <button disabled={isLoading} type="submit" className="btn  mb-40 mx-auto">{isLoading ? <Loading className="w-100" /> : "Submit"}</button>

                        </form>

                    </div>

                </div>

                <div className="w-80 mt-40">
                    <h3 className="align-left w-100 mb-20">Uploaded Schedules</h3>

                    {!isLoading ? <table className="table w-80 table-hover">
                        <thead style={{ background: "#A6CE39" }}>
                            <tr className="green-bg">
                                <th>Employer ID</th>
                                <th>Upload Date</th>
                                <th>Comments</th>
                                <th>Amount Paid</th>

                            </tr>
                        </thead>
                        <tbody>
                            {Array?.isArray(schedules) &&
                                schedules?.map((staff) => (
                                    <tr>
                                        <td>{staff?.employerId}</td>
                                        <td>{new Date(staff?.creationDate).toLocaleDateString()}</td>
                                        <td>{staff?.otherComments}</td>
                                        <td>{numberWithCommas(staff?.amountPaid)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> : <Pulse />}
                </div>


            </div>
        </div>
    )
}