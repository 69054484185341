import { useEffect, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import { get } from "../../../api-services/fetch";
import Loading from './Loading';
import Pulse from './pulse';

export default function UnfundedPins() {

    const [unfundedPins, setUnfundedPins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    

    const getStaffList = async (page = 1) => {
        setIsLoading(true);
        let res = await get({ endpoint:`api/unfundedpins?ecode=PRBNK0000070&page=${page}&pageSize=${100}` })
        console.log(res)
        if (res?.status === 200) {
            setUnfundedPins(res?.data?.data);
            setTotalPages(parseInt(res?.data?.totalCount/10))
            setIsLoading(false);
        }
        
    }

    
    const handlePageChange = (page) =>{
        setCurrentPage(page);
        getStaffList(page);
    }


    useEffect(() => {
        getStaffList();
    }, [])


    return (
        <div>
            <div className='container'>
                <div>
                    <div className="row mt-40 table-responsive w-100">
                    <div className='row space-between mt-80'>
                            <h3 className='w-50'>
                                Unfunded Pins
                            </h3>
                            {/* <div className="w-50">
                                <div class="form-group has-search">
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search here..." />
                                </div>
                            </div> */}
                        </div>
                       {!isLoading ? <table className="table w-100">
                            <thead style={{ background: "#A6CE39" }}>
                                <tr className="green-bg">
                                    <th>PIN</th>
                                    <th>Firstname</th>
                                    <th>Last Name</th>
                                    <th>Middle Name</th>
                                    <th>PIN Upload Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array?.isArray(unfundedPins) &&
                                    unfundedPins?.map((staff) => (
                                        <tr>
                                            <td>{staff?.pin}</td>
                                            <td>{staff?.firstname}</td>
                                            <td>{staff?.surname}</td>
                                            <td>{staff?.othernames}</td>
                                            <td>{new Date(staff?.uploaD_DATE).toLocaleDateString()}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table> : <Pulse /> }
                    </div>
                    <ResponsivePagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                    />
                </div>
            </div>
        </div>
    )
}