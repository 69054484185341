import { useEffect, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import { get } from "../../../../api-services/fetch";
import { BsBricks } from "react-icons/bs";
import { BsBuildingFillAdd } from "react-icons/bs";
import { BsBuildingFillSlash } from "react-icons/bs";
import Loading from "../Loading.js";
import Pulse from '../pulse.js';
const BrokerDashboard = () => {

    const [staffList, setStaffList] = useState([]);
    const [dashStats, setDashstats] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getDashBoardStats = async () => {
        // setIsLoading(false)
        let res = await get({ endpoint: `api/dashboardstats?ecode=PRBNK0000070` })
        console.log(res)
        if (res?.status === 200) {
            setDashstats(res?.data?.data);

            // setIsLoading(false)
        }

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getStaffList(page);
    }

    const getStaffList = async (page = 1) => {
        setIsLoading(true)
        let res = await get({ endpoint: `api/stafflist?ecode=PRBNK0000070&page=${page}&pageSize=${100}` })
        console.log(res)
        if (res?.status === 200) {
            setStaffList(res?.data?.data);
            setTotalPages(parseInt(res?.data?.totalCount / 10))
            setIsLoading(false);
        }

    }

    useEffect(() => {
        getDashBoardStats();
        getStaffList();
    }, [])

    return (
        <div>
            <div className="container text-left">
                <div className="row  mt-20 mb-80">
                    <div className="col-md-4">
                        <div className="flex space-between flex-v-center shadow-lg  br-6 white-bg pt-20 pl-20 br-6 pb-20 pr-20">
                            <div className="heading-and-number">
                                <div className="heading-2">Total Pins</div>
                                <div className="number-and-badge ">
                                    <p className="number">
                                        <h3 className="span w-100"><strong>{dashStats?.totalStaff || "N/A"}</strong></h3>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <BsBricks size={40} color='#A6CE39'/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="metrics shadow-lg  flex space-between flex-v-center br-6 white-bg pt-20 pb-20 pl-20  pr-20">
                            <div className="heading-and-number">
                                <div className="heading-2">Funded Pins</div>
                                <div className="number-and-badge ">
                                    <p className="number">
                                        <h3 className="span w-100"><strong>{dashStats?.totalFunded || "N/A"}</strong></h3>
                                        {/* <Loading /> */}

                                    </p>
                                </div>
                            </div>
                            <div>
                                <BsBuildingFillAdd size={40} color='#A6CE39'/>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="metrics flex space-between flex-v-center shadow-lg  br-6 white-bg pt-20 pb-20 pl-20  pr-20">
                            <div className="heading-and-number">
                                <div className="heading-2">Unfunded Pins</div>
                                <div className="number-and-badge">
                                    <p className="number">
                                        <h3 className="span w-100"><strong>{dashStats?.totalunfunded || "N/A"}</strong></h3>

                                    </p>
                                </div>
                            </div>
                            <div>
                                <BsBuildingFillSlash size={40} color='#A6CE39'/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-40 table-responsive w-100">
                <div className='row space-between mt-60'>
                            <h3 className='w-50'>
                                Staff List
                            </h3>
                            {/* <div className="w-50">
                                <div class="form-group has-search">
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search here..." />
                                </div>
                            </div> */}
                        </div>
                    {!isLoading ? <table className="table w-100">
                        <thead style={{ background: "#A6CE39" }}>
                            <tr className="green-bg">
                                <th>PIN</th>
                                <th>Firstname</th>
                                <th>Last Name</th>
                                <th>Middle Name</th>
                                <th>PIN Upload Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {staffList?.map((staff) => (
                                <tr key={staff?.pin}>
                                    <td>{staff?.pin}</td>
                                    <td>{staff?.firstname}</td>
                                    <td>{staff?.surname}</td>
                                    <td>{staff?.othernames}</td>
                                    <td>{new Date(staff?.uploaD_DATE).toLocaleDateString()}</td>
                                </tr>)
                            )}

                        </tbody>
                    </table> : <Pulse />}
                </div>
                <ResponsivePagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={page => handlePageChange(page)}
                />
            </div>
        </div>
    )
}

export default BrokerDashboard;