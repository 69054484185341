
import { Navbar, Container } from "react-bootstrap";
import Sidebar from "react-bootstrap-sidebar-menu";
import Layout from "./Layout";
import Main from "./Main";
import house from './assets/svg/home-alt.svg'
import tag from './assets/svg/clipboard.svg'
import userGroup from './assets/svg/user-group.svg'
import userGroup1 from './assets/svg/user-group1.svg'
import building from './assets/svg/newspaper.svg'
import logo from "./assets/fidpen_logo.png"


import "./styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const SideBar = ({ children }) => {
    const [show, setShow] = useState();

    const navigate = useNavigate();

    const toggleDropdown = () => {
        setShow(!show);
    };

    const handleOutsideClick = (event) => {
        if (event.target !== event.currentTarget && show) {
            setShow(false);
        }
    };

    const onLogout = () => {
        navigate("/");
        localStorage?.clear()
        sessionStorage?.clear()
    }

    return (
        <Layout >
            <Navbar className="main-header no-spacing" expand="lg" bg={"light"} variant={"light"}>
                <Container fluid className=" green-bg pl-15 pt-20 pb-20 pr-15 flex justify-content-end">

                    <div className="header"> {/* Container element */}
                        {/* Your other header content here */}
                        <div className="profile-menu" onClick={toggleDropdown}>
                            <img src={userGroup1} alt="Profile Picture" className="profile-pic rounded" />
                            <div
                                className={`dropdown-menu ${show ? 'active' : ''}`}
                                onClick={handleOutsideClick}
                            >
                                <div className="logout-btn" onClick={onLogout}>
                                    Logout
                                </div>
                                <hr />
                            </div>
                            <div className="outside-click" onClick={handleOutsideClick} />
                        </div>
                    </div>


                    {/* <div className="w-50">
                        <div class="form-group has-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" placeholder="Search here..." />
                        </div>
                    </div> */}
                    {/* <Navbar.Brand href="#home">
                    </Navbar.Brand> */}
                </Container>
            </Navbar>
            <Sidebar variant={"light"} bg={"light"} expand="sm">
                <Sidebar.Collapse getScrollValue={500}>
                    <Sidebar.Header className="">
                        <img className="w-100" src={logo} alt="" />
                        {/* <p className="logo-text text-center w-100 mt-30">TRADEBOOK</p> */}
                        {/* <Sidebar.Brand ></Sidebar.Brand> */}
                        {/* <Sidebar.Toggle /> */}
                    </Sidebar.Header>
                    <Sidebar.Body>
                        <Sidebar.Nav>
                            <Sidebar.Nav.Link eventKey="menu_title">
                                <Link to="/dashboard">
                                    <Sidebar.Nav.Icon> <img src={house} alt="" /></Sidebar.Nav.Icon>
                                    <Sidebar.Nav.Title>Dashboard</Sidebar.Nav.Title>
                                </Link>
                            </Sidebar.Nav.Link>
                            <Sidebar.Nav.Link eventKey="menu_title1">
                                <Link to="/funded-pins">
                                    <Sidebar.Nav.Icon> <img src={building} alt="" /></Sidebar.Nav.Icon>
                                    <Sidebar.Nav.Title>Funded Pins</Sidebar.Nav.Title>
                                </Link>

                            </Sidebar.Nav.Link>
                            <Sidebar.Nav.Link eventKey="menu_title2">
                                <Link to="/unfunded-pins">
                                    <Sidebar.Nav.Icon> <img src={tag} alt="" /></Sidebar.Nav.Icon>
                                    <Sidebar.Nav.Title>Unfunded Pins</Sidebar.Nav.Title>
                                </Link>

                            </Sidebar.Nav.Link>

                            <Sidebar.Nav.Link eventKey="menu_title3">
                                <Link to="/upload-schedule">
                                    <Sidebar.Nav.Icon> <img src={userGroup} alt="" /></Sidebar.Nav.Icon>
                                    <Sidebar.Nav.Title>Submit Schedule</Sidebar.Nav.Title>
                                </Link>
                            </Sidebar.Nav.Link>

                            <Sidebar.Nav.Link eventKey="menu_title4">
                                <Link to="/employer-contacts">
                                    <Sidebar.Nav.Icon> <img src={userGroup} alt="" /></Sidebar.Nav.Icon>
                                    <Sidebar.Nav.Title>Employer Contacts</Sidebar.Nav.Title>
                                </Link>
                            </Sidebar.Nav.Link>

                            {/* <Sidebar.Sub eventKey={0}>
                                <Sidebar.Sub.Toggle>
                                    <Sidebar.Nav.Icon />
                                    <Sidebar.Nav.Title>Submenu</Sidebar.Nav.Title>
                                </Sidebar.Sub.Toggle>
                                <Sidebar.Sub.Collapse>
                                    <Sidebar.Nav>
                                        <Sidebar.Nav.Link eventKey="sum_menu_title">
                                            <Sidebar.Nav.Icon>1.1</Sidebar.Nav.Icon>
                                            <Sidebar.Nav.Title>Sub menu item</Sidebar.Nav.Title>
                                        </Sidebar.Nav.Link>
                                    </Sidebar.Nav>
                                </Sidebar.Sub.Collapse>
                            </Sidebar.Sub> */}
                        </Sidebar.Nav>
                    </Sidebar.Body>
                </Sidebar.Collapse>
            </Sidebar>
            <Main>
                {children}
            </Main>
        </Layout>


    )
}

export default SideBar;