
import { useEffect, useState } from "react";
import { get, post } from "../../../api-services/fetch";
import notification from "./notification";
import Loading from "./Loading";


export default function EmployerContacts() {

    const [formData, setFormData] = useState({

    });

    const [contacts, setContacts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState();



    const getContacts = async () => {
        let res = await get({ endpoint: "api/employer-contacts?ecode=PRBNK0000070" })
        console.log(res);
        if (res?.status === 200) {
            setContacts(res?.data?.data);
        }
    }

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const closeModal = () => {
        setShowModal(false);
    }



    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        console.log(formData)

        let res = await post({ endpoint: "api/employer-contacts", body: {...formData, employerId: "PRBNK0000070"} })

        if (res?.status === 200) {
            console.log(res)
            notification({
                title: "Contact Updated",
                message: res?.data,
                type: "success",
            });
            setIsLoading(false)
        }
        else {
            notification({
                title: "Error",
                message: res?.data?.message,
                type: "danger",
            });
            setIsLoading(false)

        }
        getContacts();
        setFormData({
            employerId: "",
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: ""
        });

    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

    };

    const handleFileChange = (e) => {
        const { name, value } = e.target;
        if (e.target.files) {

            setFormData({
                ...formData,
                [name]: e.target.files[0],
            });
        }
    };


    useEffect(() => {
        getContacts();
    }, [])



    return (
        <div>
            <div className='d-flex card white-bg flex-column align-items-center'>
                <div className='w-100 d-flex justify-content-center'>
                    {showModal &&
                        <div className="container mt-40">
                            <div
                                className="overlay"
                                style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "40px",
                                    boxShadow: "0px 2px 6px #0000000A",
                                }}
                            >
                                {/* <div onClick={closeModal} style={{ position: "fixed", top: '15px', right: "152px", color: "white", cursor: "pointer" }} className=" red-bg p-20">x</div> */}
                                <div className="modal-box w-80 pd-10  justify-content-center">

                                    <h2 className='font-weight-bold w-100 pl-40 pt-80'>Add Contact</h2>

                                    <form className=' customLabels pr-40 pl-40' onSubmit={handleSubmit}>
                                        {/* <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="employerId" >Employer ID</label>
                                                <input type="text" className="form-control" id="employerId" name='employerId' placeholder="Enter ID" value={formData?.employerId}
                                                    onChange={handleChange} />
                                            </div>
                                        </div> */}

                                        <div className='form-row'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" className="form-control" id="firstName" name='firstName' placeholder="First Name" value={formData?.firstName}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className='form-row'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" className="form-control" id="lastName" name='lastName' placeholder="Last Name" value={formData?.lastName}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>


                                        <div className='form-row'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="email">Email</label>
                                                <input type="text" className="form-control" id="email" name='email' placeholder="Email" value={formData?.email}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className='form-row'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="mobileNumber">Mobile Number</label>
                                                <input type="number" className="form-control" id="mobileNumber" name='mobileNumber' placeholder="Mobile Number" value={formData?.mobileNumber}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>


                                        <button disabled={isLoading} type="submit" className="genric-btn success mr-40 mb-40">{isLoading ? <Loading className="w-100 " /> : "Submit"}</button>
                                        <button onClick={closeModal} disabled={isLoading}  className=" genric-btn danger mb-40 mx-auto">{"Close"}</button>

                                    </form>
                                </div>
                            </div >
                        </div>
                    }




                    <div className='container'>
                        <div >
                            <div className="row mt-40 table-responsive w-100">
                                <div className="mt-80 mb-20 flex-end">
                                    <button onClick={handleShowModal} className="btn "><div className=" flex flex-h-center flex-v-center"><span className="  font-size-40">+</span> <span className="font-size-20 center-text">Add Employer</span> </div></button>
                                </div>

                                <div className='row space-between mt-20'>
                                    <h3 className='w-50'>
                                        Employer Contacts
                                    </h3>
                                    {/* <div className="w-50">
                                        <div class="form-group has-search">
                                            <span class="fa fa-search form-control-feedback"></span>
                                            <input type="text" class="form-control" placeholder="Search here..." />
                                        </div>
                                    </div> */}
                                </div>

                                <table className="table w-100">
                                    <thead style={{ background: "#A6CE39" }}>
                                        <tr className="green-bg">
                                            <th>Employer ID</th>
                                            <th>First Name</th>
                                            <th>Surname</th>
                                            <th>Email</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array?.isArray(contacts) &&
                                            contacts?.map((staff) => (
                                                <tr key={staff?.employerId}>
                                                    <td>{staff?.employerId}</td>
                                                    <td>{staff?.firstName}</td>
                                                    <td>{staff?.lastName}</td>
                                                    <td>{staff?.email}</td>
                                                    <td>{staff?.mobileNumber}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}