import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/flaticon.css'
import './assets/css/fontawesome-all.min.css'
import './assets/css/style.css'
import { Routes, Route} from 'react-router-dom';
// import Footer from './shared/Footer';
import { ReactNotifications } from 'react-notifications-component'
import Sidebar from './Sidebar'
import { routeConfig } from './Components/RouteConfig';
import React from 'react';
import Login from './Components/Clients/Login/login';
// import '~react-pro-sidebar/dist/css/styles.scss';
// import {Started} from './Components/Clients/started';


function App() {

  return (


    <div className="wrapper container-fluid no-gutters">
      <div className='row no-gutters'>
        <div id='content' className={'col-md-12'}>
        <ReactNotifications />
          <Routes>
            <Route path="/" element={<Login />} />
            {routeConfig.map(({ path, component, needsNav }) => (
              <Route
                key={path}
                path={path}
                element={needsNav ? <Sidebar>{React.createElement(component)}</Sidebar> : React.createElement(component)}
              />
            ))}
          </Routes>

          {/* <Sidebar>
            <AppRoute />
          </Sidebar> */}

        </div>

      </div>







    </div>
  );
}

export default App;
